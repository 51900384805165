import * as React from 'react';
import { CSSProperties } from '@material-ui/styles';
import clsx from 'clsx';

import baseStyles from 'components/Themes/CoolProfessional/base.module.css';

import styles from './ProductListView.module.css';

interface Props {
  imageUrls: string[];
  bannerTitle: string;
  bannerDescription: string;
}

export const KeyVisualView = ({ imageUrls, bannerTitle, bannerDescription }: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const mainImageStyle: CSSProperties = {
    transform: `translateX(-${activeIndex * 100}%)`,
  };

  const handlePrev = () =>
    setActiveIndex(activeIndex <= 0 ? imageUrls.length - 1 : activeIndex - 1);

  const handleNext = () =>
    setActiveIndex(activeIndex >= imageUrls.length - 1 ? 0 : activeIndex + 1);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 5 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex]);

  if (imageUrls.length === 0) {
    return null;
  }

  return (
    <section className={styles['mainvisual']}>
      <div className={styles['mainvisual__pic']}>
        <div className={styles['c-photo__main']}>
          <ul className={styles['c-photo__main__list']}>
            {imageUrls.map((url) => (
              <li className={styles['c-photo__main__list__item']} style={mainImageStyle} key={url}>
                <img src={url} alt="banner" />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={clsx(
          imageUrls.length > 1 && styles['desktop-indent'],
          baseStyles['base-inner'],
          styles['mainvisual__desc'],
          baseStyles['color-txt--white']
        )}
      >
        <h2>{bannerTitle}</h2>
        <span className={baseStyles['color-bg--white']}></span>
        <p>{bannerDescription}</p>
      </div>
      <p className={clsx(styles['mainvisual__scroll'], baseStyles['color-txt--white'])}>
        SCROLL<span></span>
      </p>
      {imageUrls.length > 1 && (
        <>
          <button
            className={clsx(styles['pictures__all__photo__btn'], styles['prev'])}
            onClick={handlePrev}
          />
          <button
            className={clsx(styles['pictures__all__photo__btn'], styles['next'])}
            onClick={handleNext}
          />
        </>
      )}
    </section>
  );
};
